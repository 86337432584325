import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { StringParam, useQueryParams } from 'use-query-params';
import { unpackToDate, unpackToDateTime } from '@campfire/hot-date';
import { intersection } from 'lodash';

import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import {
  ActivityReportGetActivityReport,
  ActivityReportGetActivityReportVariables,
} from '../__generated__/ActivityReportGetActivityReport';
import { GET_ACTIVITY_REPORT } from '../activity-report-card-model.gql';
import { SessionReport, SessionReportType } from './SessionReport';
import { AlertCard } from '../../../../common/cards/alert-card/AlertCard';
import { useOrgInfo } from '../../../../global/auth/use-org-info/use-org-info';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      minHeight: 72,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
      '& .header': {
        fontSize: 20,
        fontWeight: 900,
        color: 'rgb(62, 50, 41)',
      },
      '& .subHeader': {
        fontSize: 14,
        fontWeight: 700,
        color: theme.color.grey.neutralBrand400,
        paddingTop: 2,
      },
    },
    reportHeader: {
      paddingTop: 36,
      paddingBottom: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .title': {
        fontSize: 16,
        fontWeight: 900,
        color: theme.color.grey.neutralBrand800,
      },
      '& .subtitle': {
        fontSize: 11,
        fontWeight: 500,
        color: theme.color.grey.neutralBrand400,
      },
    },
  })
);

export function ActivityReport({
  selectedActivityId,
  selectedActivityDate,
  displayOnWidget,
  refetchMainList,
}: {
  selectedActivityId?: string;
  selectedActivityDate?: string;
  displayOnWidget?: boolean;
  refetchMainList?: () => void;
}) {
  const [
    queryActivityReport,
    { data: vmActivityReport, loading: activityReportLoading, refetch },
  ] = useCampfireLazyQuery<ActivityReportGetActivityReport, ActivityReportGetActivityReportVariables>(
    GET_ACTIVITY_REPORT
  );

  const [{ activityId: queryActivityId, activityDate: queryActivityDate }] = useQueryParams({
    activityId: StringParam,
    activityDate: StringParam,
  });

  const activityId = displayOnWidget ? selectedActivityId : queryActivityId;
  const activityDate = displayOnWidget ? selectedActivityDate : queryActivityDate;

  React.useEffect(() => {
    if (activityId && activityDate) {
      queryActivityReport({
        variables: {
          activityId,
          activityDate,
        },
      });
    }
  }, [activityId, activityDate]);

  const activity = vmActivityReport?.vm?.activity;
  const activityReport = vmActivityReport?.vm?.activityReport;

  const activeSessions = activity?.allSessions.filter((session) => session.dateRemoved === null) || [];
  const removedSessions = activity?.allSessions.filter((session) => session.dateRemoved !== null) || [];

  const nonNulledSessionReports = activeSessions.filter((s) => {
    if (s.reportType === null) {
      return vmActivityReport?.vm.activity?.publishedRoster === null;
    }
    return s.reportType !== null;
  });

  const numberSummitted = intersection(
    activeSessions.map((sesion) => sesion.sessionId),
    [
      ...(activityReport?.sessionReports.map((sessionReport) => sessionReport.session.sessionId) || []),
      ...(activityReport?.cancelledSessions.map((session) => session.sessionId) || []),
    ]
  ).length;

  const numRemoved = intersection(
    removedSessions.map((sesion) => sesion.sessionId),
    [
      ...(activityReport?.sessionReports.map((sessionReport) => sessionReport.session.sessionId) || []),
      ...(activityReport?.cancelledSessions.map((session) => session.sessionId) || []),
    ]
  ).length;

  const classes = useStyles();

  const noOfHiddenReports = activityReport?.sessionReports.filter((sr) => sr.isHidden).length || 0;

  const isHiddenReport = noOfHiddenReports > 0;
  const allHiddeenReport = noOfHiddenReports > 0 && noOfHiddenReports === (activityReport?.sessionReports || []).length;

  React.useEffect(() => {
    if (refetchMainList) {
      if (nonNulledSessionReports?.length - noOfHiddenReports === numberSummitted - noOfHiddenReports)
        refetchMainList();
    }
  }, [noOfHiddenReports, nonNulledSessionReports, numberSummitted]);

  const { name } = useOrgInfo() || {};

  if (!activityId || !activityDate) {
    return null;
  }

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <Typography className='header' variant='h5'>
          {activity?.name}
        </Typography>
        <Typography className='subHeader' variant='h4'>
          {activityDate ? unpackToDate(activityDate).toFormat('dd LLLL, yyyy') : ''} | {activity?.program.name}
        </Typography>
      </Box>
      <Box className={classes.reportHeader}>
        <Typography className='title'>Reports</Typography>
        {activityReportLoading ? (
          <Skeleton width={75} height={25} />
        ) : allHiddeenReport ? null : (
          <Typography className='subtitle'>
            {`${numberSummitted - noOfHiddenReports}/${nonNulledSessionReports?.length - noOfHiddenReports} Submitted`}
          </Typography>
        )}
      </Box>
      {allHiddeenReport && (
        <Box paddingBottom={'8px'}>
          <AlertCard variant='info' title={`${name} has hidden all reports from sessions you were not apart of`} />
        </Box>
      )}
      {isHiddenReport && !allHiddeenReport && (
        <Box paddingBottom={'8px'}>
          <AlertCard variant='info' title={`${name} has hidden reports from sessions you were not apart of`} />
        </Box>
      )}
      {activityReportLoading && <Skeleton variant='rect' width='100%' height={75} />}
      <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
        {activeSessions.map((session) => {
          const sessionReport = activityReport?.sessionReports?.find(
            (sr) => sr.session.sessionId === session.sessionId
          );
          const cancelledSession = activityReport?.cancelledSessions.find((cs) => cs.sessionId === session.sessionId);
          const isCompletedCICO = sessionReport?.CICOs.every((CICO) => CICO.checkIn && CICO.checkOut);
          const isFilledRequiredFields =
            sessionReport &&
            sessionReport.CICOs.length > 0 &&
            session.reportType?.items
              .filter((i) => i.__typename === 'VOLUNTEER_ReportTypeFieldType' && !i.optional)
              .every(
                (item) =>
                  item.__typename === 'VOLUNTEER_ReportTypeFieldType' &&
                  sessionReport?.fieldValues.find((fieldValue) => fieldValue.field.fieldId === item.field.fieldId)
              );

          const variant =
            sessionReport && isCompletedCICO && (isFilledRequiredFields || !sessionReport.CICOs.length)
              ? 'completed'
              : cancelledSession
              ? 'cancelled'
              : session.reportType === null &&
                (session.autoReport === undefined
                  ? unpackToDateTime(`${activityDate}T${session.endTime}`)
                      .toJSDate()
                      .getTime() >=
                    unpackToDateTime(session.autoReport)
                      .toJSDate()
                      .getTime()
                  : true) &&
                sessionReport === undefined
              ? 'null'
              : 'incomplete';

          if (sessionReport?.isHidden) {
            return null;
          }

          return (
            <SessionReport
              key={session.sessionId}
              variant={variant}
              session={session}
              sessionReport={sessionReport as SessionReportType}
              activityDate={activityDate as string}
              activityName={activity?.name}
              publishedRoster={activity?.publishedRoster}
              onEdit={refetch}
            />
          );
        })}
      </Box>
      {numRemoved > 0 && (
        <React.Fragment>
          <Box className={classes.reportHeader}>
            <Typography className='title'>Removed session reports</Typography>
            {activityReportLoading ? (
              <Skeleton width={75} height={25} />
            ) : (
              <Typography className='subtitle'>{`${numRemoved} Removed`}</Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
            {activityReportLoading ? (
              <Skeleton variant='rect' width='100%' height={75} />
            ) : (
              removedSessions.map((session) => {
                const sessionReport = activityReport?.sessionReports?.find(
                  (sr) => sr.session.sessionId === session.sessionId
                );
                const cancelledSession = activityReport?.cancelledSessions.find(
                  (cs) => cs.sessionId === session.sessionId
                );
                const variant = sessionReport
                  ? 'completed'
                  : cancelledSession
                  ? 'cancelled'
                  : session.reportType === null &&
                    (session.autoReport === undefined
                      ? unpackToDateTime(`${activityDate}T${session.endTime}`)
                          .toJSDate()
                          .getTime() >=
                        unpackToDateTime(session.autoReport)
                          .toJSDate()
                          .getTime()
                      : true) &&
                    sessionReport === undefined
                  ? 'null'
                  : 'incomplete';

                return (
                  <SessionReport
                    variant={variant}
                    session={session}
                    sessionReport={sessionReport as SessionReportType}
                    activityDate={activityDate as string}
                    publishedRoster={activity?.publishedRoster}
                    onEdit={refetch}
                  />
                );
              })
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
