import React from 'react';
import { DateTime } from 'luxon';
import { encodeDate, unpackToTime } from '@campfire/hot-date';
import { MyShiftItem } from '../MyShiftItem';
import { RosteredStatus, RosterTimelineItem, ActionType } from '../../ActivityTypes';
import { VolunteerRosters } from '../../ActivityQueries/__generated__/VolunteerRosters';
import { MyActivityStatusIndicator } from '../../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/MyActivityStatusIndicator';
import { useUser } from '../../../../../global/auth/useUser';
import { MyActivityCICOActions } from '../../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/MyActivityCICOActions';

interface Props {
  activities: RosterTimelineItem[];
  onAction: (action: ActionType, activity: RosterTimelineItem) => void;
  onSelect: (activityId: string, activityDate: string) => void;
  selectedActivityId?: string;
  selectedActivityDate?: string;
  getColor: (activityStatus: RosteredStatus) => any;
  volunteerRosterings?: VolunteerRosters[];
  getRosterActions: (activity: RosterTimelineItem) => ActionType[];
  onCICOAction: (activity: any, type: any, isAbleCheckOut: boolean) => void;
  vmAllowCICO: boolean;
}

export function MyShiftTimeline({
  activities,
  onAction,
  onSelect,
  selectedActivityId,
  getColor,
  selectedActivityDate,
  volunteerRosterings,
  getRosterActions,
  onCICOAction,
  vmAllowCICO,
}: Props) {
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  return (
    <React.Fragment>
      {activities.map((activity: RosterTimelineItem) => {
        const nowDate = encodeDate(DateTime.local());
        const activeRosterings = activity.publishedRoster?.activeRosterings;
        const activeRostering = activeRosterings?.find((item) => item.volunteer.volunteerId === volunteerId);
        const isAttended = activeRostering?.sessionRosterings.every((item) =>
          activeRostering?.volunteer.CICOs.find(
            (cico) =>
              cico.checkIn &&
              cico.checkOut &&
              cico.sessionReport.activityReport.activityDate === activity.activityDate &&
              cico.sessionReport.activityReport.activity.activityId === activity.activityId &&
              cico.sessionReport.session.sessionId === item.session.sessionId
          )
        );
        const checkInTimeRule =
          unpackToTime(activity.startTime)
            .diffNow()
            .as('hours') <= 6;
        const activityDate = activeRostering?.volunteer.checkedIn?.sessionReport.activityReport.activityDate;
        const isAbleCheckIn = nowDate === activity.activityDate && checkInTimeRule && activity.status === 'rostered';
        const isAbleCheckOut =
          activity.status === 'rostered' &&
          activityDate === activity.activityDate &&
          !!activeRostering?.volunteer?.isCheckedIn;
        const ableCICO =
          (activity.allowCICO || activity.allowCICO === null) &&
          (activity.program.allowCICO || activity.program.allowCICO === null) &&
          vmAllowCICO &&
          !isAttended &&
          (isAbleCheckIn || isAbleCheckOut);

        return (
          <MyShiftItem
            activity={activity}
            ActivityMenu={
              ableCICO ? (
                <MyActivityCICOActions
                  activity={activity}
                  onAction={(type) => onCICOAction(activity, type, isAbleCheckOut)}
                  isCheckOut={isAbleCheckOut}
                />
              ) : (
                <MyActivityStatusIndicator
                  actions={getRosterActions(activity) as any[]}
                  onAction={(action) => onAction(action, activity)}
                  status={activity.status}
                />
              )
            }
            isSelected={selectedActivityId === activity.activityId && selectedActivityDate === activity.activityDate}
            onSelect={() => onSelect(activity.activityId, activity.activityDate)}
            color={getColor(activity.status)}
            rostering={volunteerRosterings?.find(
              (volunteerRostering) =>
                volunteerRostering.publishedRoster.activityDate === activity.activityDate &&
                volunteerRostering.publishedRoster.activity.activityId === activity.activityId
            )}
          />
        );
      })}
    </React.Fragment>
  );
}
