import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import { RolePanel } from '../RolePanel';
import { RoleItem, ExtendRole } from './RoleItem';

interface Props {
  roles: ExtendRole[];
  isLoading: boolean;
  onEdit: (role: ExtendRole) => void;
  onDelete: (role: ExtendRole) => void;
  onShowVolunteers: (roleId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand900,
    },
    body: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
      marginTop: '6px',
    },
  })
);

export function RolesList({ roles, onEdit, onDelete, onShowVolunteers, isLoading }: Props) {
  const classes = useStyles();
  return (
    <>
      <RolePanel>
        <Typography className={classes.heading} variant='h4'>
          Roles
        </Typography>
        <Typography className={classes.body}>
          You have {roles.length} roles for your volunteers. If you need to create more roles click the ‘
          <strong>Add New Role</strong>’ button top right.
        </Typography>
        {roles.map((role: ExtendRole) => (
          <RoleItem
            role={role}
            onEdit={onEdit}
            onDelete={onDelete}
            onShowVolunteers={onShowVolunteers}
            isLoading={isLoading}
          />
        ))}
      </RolePanel>
    </>
  );
}
