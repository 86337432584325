import { CircularProgressOverlay } from '@campfire/circular-progress-overlay/lib';
import { Pane, PaneHeader, PaneScrollable } from '@campfire/pane';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { KeyboardTab } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Wordmark from '../../../assets/branding/logo/volaby-original-dark.svg';
import MobileWordmark from '../../../assets/branding/logo/volaby-original.svg';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { Navigation, NavigationBlock } from '../../../content-blocks/common/Navigation';
import { useRoutesMap } from '../../../content-blocks/common/useRoutesMaps';
import { FourOhFourScreen } from '../../../screens';
import { SignOutScreen } from '../../../screens/public-screens/logout/SignOutScreen';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useSession } from '../../auth/useSession';
import { useUser } from '../../auth/useUser';
import { useApiUrl } from '../../config/useApiUrl';
import { writeMessageToLog } from '../../logging/LogService';
import { usePageNotFoundContext } from '../../page-not-found/PageNotFoundContext';
import { signInPath } from '../../public-shell/sign-in-path';
import { AppBar } from '../app-bar/AppBar';
import { useRedirectContext } from '../RedirectContext';

const useStyles = makeStyles(() =>
  createStyles({
    closeMenuIcon: {
      color: '#646464',
      background: '#444444',
      borderRadius: '6px',
      height: 24,
      width: 30,
      padding: 0,
      marginRight: 16,
      '&:hover': {
        color: '#FCFCFC',
        background: '#444444',
      },
    },
  })
);
const FixedContentBlock = React.lazy(() => import('../../../content-blocks/fixed/FixedContentBlock'));
const VolunteeringContentBlock = React.lazy(() =>
  import('../../../content-blocks/volunteering/VolunteeringContentBlock')
);
const ManagementContentBlock = React.lazy(() => import('../../../content-blocks/management/ManagementContentBlock'));
const AdminContentBlock = React.lazy(() => import('../../../content-blocks/admin/AdminContentBlock'));

const RoutedContent = () => {
  const routesMaps = useRoutesMap();
  return (
    <Suspense fallback={<CircularProgressOverlay spinnerColor='white' opacity={0} isLoading transitionDelay={150} />}>
      <CampfireSwitch>
        <Route path='/sign-out' component={SignOutScreen} />

        <Route path='/general'>
          <Redirect to={signInPath} />
        </Route>

        <Route path='/activity-leader'>
          <Redirect to={signInPath} />
        </Route>

        <Route path='/vol-manager'>
          <Redirect to={signInPath} />
        </Route>

        <Route path={`/${routesMaps.fixed.route}`} component={FixedContentBlock} />
        <Route path={`/${routesMaps.volunteering.route}`} component={VolunteeringContentBlock} />
        <Route path={`/${routesMaps.management.route}`} component={ManagementContentBlock} />
        <Route path={`/${routesMaps.admin.route}`} component={AdminContentBlock} />
        <Route exact path={signInPath} render={() => <LandingRoute />} />
        {/* todo remove this hardcoded */}
        <Route exact path={'/apply'} render={() => <LandingRoute />} />
        <Route exact path={'/'} render={() => <LandingRoute />} />
      </CampfireSwitch>
    </Suspense>
  );
};

type LayoutProps = {
  activeSideNavRoute: string | undefined;
  setActiveSideNavRoute: (route: string) => void;
  isDrawerOpen: boolean;
  toggleDrawerClosed: () => void;
  toggleDrawerOpen: () => void;
};

const MobileDrawerLayout: FunctionComponent<LayoutProps> = (props) => {
  const {
    children,
    activeSideNavRoute,
    setActiveSideNavRoute,
    isDrawerOpen,
    toggleDrawerClosed,
    toggleDrawerOpen,
  } = props;
  const routesMaps = useRoutesMap();

  return (
    <>
      <SwipeableDrawer
        disableBackdropTransition
        disableSwipeToOpen
        disableDiscovery
        open={isDrawerOpen}
        onOpen={() => {}}
        onClose={toggleDrawerClosed}
      >
        <Box width={250}>
          <Box display='flex' justifyContent='space-between' alignItems='center' alignContent='center' width={1}>
            <Box marginLeft={2} marginTop={2}>
              <img src={MobileWordmark} width={148} alt='volaby-logo' />
            </Box>
          </Box>
          <NavigationBlock
            routesMap={routesMaps.fixed}
            {...props}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={toggleDrawerClosed}
            activeSideNavRoute={activeSideNavRoute || ''}
          />
          <Navigation
            activeSideNavRoute={activeSideNavRoute || ''}
            setActiveSideNavRoute={setActiveSideNavRoute}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={toggleDrawerClosed}
          />
        </Box>
      </SwipeableDrawer>
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          minHeight: window.innerHeight,
          backgroundColor: '#ffffff',
          overflow: 'hidden',
        }}
      >
        <AppBar isDrawerOpen={isDrawerOpen} toggleDrawerOpen={toggleDrawerOpen} />
        {children}
      </main>
    </>
  );
};

const DesktopDrawerLayout: FunctionComponent<LayoutProps> = (props) => {
  const {
    children,
    activeSideNavRoute,
    setActiveSideNavRoute,
    isDrawerOpen,
    toggleDrawerClosed,
    toggleDrawerOpen,
  } = props;
  const routesMaps = useRoutesMap();
  const { theme, isMd } = useCampfireTheme();
  const darkColor = '#282828';
  const drawerWidth = theme.appShell.drawer.width;
  const classes = useStyles();

  useEffect(() => {
    if (isMd && isDrawerOpen) {
      toggleDrawerClosed();
    }
    if (!isMd && !isDrawerOpen) {
      toggleDrawerOpen();
    }
  }, [isMd]);

  return (
    <Box display='flex'>
      {isDrawerOpen && (
        <Box display='flex' flexShrink={0} height='100vh' width={drawerWidth} style={{ backgroundColor: darkColor }}>
          <Pane>
            <PaneHeader>
              <Box bgcolor={darkColor} width={1}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  alignContent='center'
                  width={1}
                  height={theme.appShell.menu.height}
                >
                  <Box marginLeft={2} mt={1}>
                    <img src={Wordmark} width={130} alt='volaby-logo' />
                  </Box>
                  <IconButton onClick={toggleDrawerClosed} className={classes.closeMenuIcon}>
                    <KeyboardTab
                      style={{
                        height: 18,
                        width: 24,
                        transform: 'scaleX(-1)',
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </PaneHeader>
            <PaneScrollable style={{ backgroundColor: darkColor }}>
              <Box>
                <NavigationBlock
                  routesMap={routesMaps.fixed}
                  {...props}
                  activeSideNavRoute={activeSideNavRoute || ''}
                  isDrawerOpen={isDrawerOpen}
                  closeDrawer={toggleDrawerClosed}
                />
                <Navigation
                  activeSideNavRoute={activeSideNavRoute || ''}
                  setActiveSideNavRoute={setActiveSideNavRoute}
                  isDrawerOpen={isDrawerOpen}
                  closeDrawer={toggleDrawerClosed}
                />
              </Box>
            </PaneScrollable>
          </Pane>
        </Box>
      )}

      <main
        style={{
          width: isDrawerOpen ? `calc(100vw - ${drawerWidth}px)` : '100vw',
        }}
      >
        <AppBar isDrawerOpen={isDrawerOpen} toggleDrawerOpen={toggleDrawerOpen} />
        <Box
          style={{
            height: `calc(100vh - ${theme.appShell.menu.height}px)`,
            overflow: 'auto',
            backgroundColor: '#ffffff',
          }}
        >
          {children}
        </Box>
      </main>
    </Box>
  );
};

const DrawerContent: FunctionComponent = () => {
  const { isMobile, isLandscape } = useCampfireTheme();
  const [activeSideNavRoute, setActiveSideNavRoute] = useState<string>();
  const { pageNotFound } = usePageNotFoundContext();
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  if (pageNotFound && !isDrawerOpen && !isMobile) setDrawerOpen(true);

  return (
    <>
      {isMobile || isLandscape ? (
        <MobileDrawerLayout
          activeSideNavRoute={activeSideNavRoute}
          setActiveSideNavRoute={setActiveSideNavRoute}
          isDrawerOpen={isDrawerOpen}
          toggleDrawerClosed={() => setDrawerOpen(false)}
          toggleDrawerOpen={() => setDrawerOpen(true)}
        >
          {pageNotFound ? <FourOhFourScreen /> : <RoutedContent />}
        </MobileDrawerLayout>
      ) : (
        <DesktopDrawerLayout
          activeSideNavRoute={activeSideNavRoute}
          setActiveSideNavRoute={setActiveSideNavRoute}
          isDrawerOpen={isDrawerOpen}
          toggleDrawerClosed={() => setDrawerOpen(false)}
          toggleDrawerOpen={() => setDrawerOpen(true)}
        >
          {pageNotFound ? <FourOhFourScreen /> : <RoutedContent />}
        </DesktopDrawerLayout>
      )}
    </>
  );
};

const LandingRoute = () => {
  const { user } = useSession();
  const apiUrl = useApiUrl();
  const { maybeVolunteerIdentity } = useUser();
  const { redirectUrl } = useRedirectContext();
  const routesMaps = useRoutesMap();

  if (redirectUrl !== '/' && redirectUrl !== signInPath) {
    return <Redirect to={redirectUrl} />;
  }

  if (user?.userIdentityService.isProgramManager) {
    return <Redirect to={`/${routesMaps.management.route}/dashboard`} />;
  }

  if (maybeVolunteerIdentity) {
    return <Redirect to={'/warmth/my-elements'} />;
  }

  if (user) {
    writeMessageToLog('error', user.token, apiUrl, `LandingRoute resulted in Sign Out: ${user.token}`);
  }

  return <Redirect to={'/sign-out'} />;
};

export { DrawerContent };
