import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { EditorState, convertToRaw } from 'draft-js';
import { Add } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { AlertCard } from '../../../../../common/cards/alert-card/AlertCard';
import { EmptyRoles } from './EmptyRoles';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { ADMIN_CONSOLE_ROLE_QUERY } from './AdminConsoleRolesQuery/AdminConsoleRolesQuery.gql';
import { VOLUNTEER_ROLES_QUERY } from './VolunteerRoleListQuery/VolunteerRoleListQuery.gql';
import { GetVolunteerRoles } from './VolunteerRoleListQuery/__generated__/GetVolunteerRoles';
import { AdminConsoleRole } from './AdminConsoleRolesQuery/__generated__/AdminConsoleRole';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useDeepEffect } from '../../../../../hooks/useDeepEffect';
import { TemplateSnackbar, SnackBarOptions } from '../../../../../common/snackbars/TemplateSnackbar';
import { GetAdminConsoleRoles } from './AdminConsoleRolesQuery/__generated__/GetAdminConsoleRoles';
import { RolesList } from './RolesList';
import { NewRole, ValueType } from './NewRole';
import { EditRole } from './EditRole';
import { VolunteersList } from './VolunteersList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: 20,
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

type VolunteerRoleType = { [key: string]: { [key: string]: boolean } };

export function AdminConsoleRoles() {
  const classes = useStyles();
  const [snackbar, setSnackBar] = React.useState<SnackBarOptions>();
  const { data, refetch } = useCampfireQuery<GetAdminConsoleRoles, {}>(ADMIN_CONSOLE_ROLE_QUERY);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [name, setName] = React.useState('');
  const [icon, setIcon] = React.useState('');
  const [showRoleDialog, setShowRoleDialog] = React.useState(false);
  const [role, setRole] = React.useState<AdminConsoleRole>();
  const [volunteerRoleId, setVolunteerRoleId] = React.useState<string>();
  const apiUrl = useApiUrl();

  const { data: volunteerData, refetch: refetchVolunteer, loading: getRoleLoading } = useCampfireQuery<
    GetVolunteerRoles,
    {}
  >(VOLUNTEER_ROLES_QUERY);

  const [volunteerRoles, setVolunteerRoles] = React.useState<VolunteerRoleType>({});
  useDeepEffect(() => {
    if (volunteerData) {
      setVolunteerRoles(
        volunteerData?.vm.volunteers.reduce(
          (acc, volunteer) => ({
            ...acc,
            [volunteer.volunteerId]: volunteer.activityRoles.reduce(
              (accRole, currentRole) => ({
                ...accRole,
                [currentRole.activityRoleId]: true,
              }),
              {}
            ),
          }),
          {}
        )
      );
    }
  }, [volunteerData]);

  const onChange = ({ name: newName, icon: newIcon, description }: ValueType) => {
    setName(newName);
    setIcon(newIcon);
    setEditorState(description);
  };

  const campfireMutation = useCampfireFetch({ defer: true });

  const onCreateNewRole = (event: any) => {
    event.preventDefault();
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/save`,
        method: 'post',
        data: {
          name,
          icon,
          description: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'A new role have been created!',
        });
        setShowRoleDialog(false);
      });
  };

  const onSave = (updatingRole: any) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/save`,
        method: 'post',
        data: {
          name: updatingRole.name,
          icon: updatingRole.icon,
          description: JSON.stringify(convertToRaw(updatingRole.description.getCurrentContent())),
          activityRoleId: updatingRole.activityRoleId,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setRole(undefined);
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Role updated!',
        });
      });
  };

  const onEdit = setRole;
  const onDelete = (deletingRole: any) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/remove`,
        method: 'post',
        data: {
          activityRoleId: deletingRole.activityRoleId,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Delete role successfully!',
        });
      });
  };

  const onApplyRole = (volunteerId: string) => {
    if (!volunteerRoleId) {
      return;
    }

    const updatingVolunteerRoles = {
      ...volunteerRoles,
      [volunteerId]: {
        ...volunteerRoles[volunteerId],
        [volunteerRoleId]: !volunteerRoles[volunteerId][volunteerRoleId],
      },
    };
    setVolunteerRoles(updatingVolunteerRoles);

    campfireMutation
      .run({
        url: `${apiUrl}/vm/volunteer/manage/update-activity-roles`,
        method: 'post',
        data: {
          volunteerId: volunteerId,
          activityRoleIds: Object.keys(updatingVolunteerRoles[volunteerId]).filter(
            (key) => updatingVolunteerRoles[volunteerId][key]
          ),
        },
      })
      .then(() => {
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Role saved!',
        });
      });
  };

  const onShowVolunteers = (roleId: string) => {
    setVolunteerRoleId(roleId);
  };
  const selectedVolunteerRole = data?.vm.activityRoles.find(
    (activityRole) => activityRole.activityRoleId === volunteerRoleId
  );
  const selectableVolunteers = volunteerData?.vm.volunteers.map((volunteer) => ({
    ...volunteer,
    checked: Boolean(
      volunteerRoleId && volunteerRoles[volunteer.volunteerId] && volunteerRoles[volunteer.volunteerId][volunteerRoleId]
    ),
  }));

  const onCloseVolunteerDialog = () => {
    setVolunteerRoleId(undefined);
    if (refetchVolunteer) {
      setTimeout(refetchVolunteer);
    }
  };

  const roles = data?.vm.activityRoles.map((activityRole) => ({
    ...activityRole,
    volunteerCount: (volunteerData?.vm.volunteers || []).filter((volunteer) =>
      volunteer.activityRoles.some((volunteerRole) => volunteerRole.activityRoleId === activityRole.activityRoleId)
    ).length,
  }));

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography variant='h2' className={classes.headingTitle}>
          Roles
        </Typography>
        <TabletButton endIcon={<Add />} variant='contained' color='primary' onClick={() => setShowRoleDialog(true)}>
          Add New Role
        </TabletButton>
      </Box>
      <Box marginTop='1.5rem'>
        <AlertCard variant='info' title='What are Roles?'>
          <Typography className={classes.infoBody}>
            Roles are a great way to segment your volunteer base into the different &quot;jobs&quot; they might be
            qualified for.
            <br />
            This means if you have certain sessions that require someone to Drive, you might include this as a role.
            This is going to allow you to get the right people in the right place.
          </Typography>
        </AlertCard>
      </Box>
      {roles?.length ? (
        <RolesList
          roles={roles}
          onEdit={onEdit}
          onDelete={onDelete}
          onShowVolunteers={onShowVolunteers}
          isLoading={getRoleLoading}
        />
      ) : (
        <EmptyRoles />
      )}
      {showRoleDialog && (
        <NewRole
          close={() => setShowRoleDialog(false)}
          value={{ name, icon, description: editorState }}
          onChange={onChange}
          onCancel={() => setShowRoleDialog(false)}
          onSave={onCreateNewRole}
        />
      )}
      {role && (
        <EditRole role={role} onCancel={() => setRole(undefined)} close={() => setRole(undefined)} onSave={onSave} />
      )}
      {volunteerRoleId && (
        <VolunteersList
          volunteers={selectableVolunteers || []}
          onClose={onCloseVolunteerDialog}
          title={selectedVolunteerRole?.name || ''}
          onChange={onApplyRole}
        />
      )}
      {snackbar && <TemplateSnackbar {...snackbar} onClose={() => setSnackBar(undefined)} />}
    </Box>
  );
}
