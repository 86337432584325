import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Add } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { uniqBy } from 'lodash';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { TemplateSnackbar, SnackBarOptions } from '../../../../../common/snackbars/TemplateSnackbar';
import { ADMIN_CONSOLE_TAG_QUERY } from './admin-console-tags.gql';
import { ACTIVITY_TAGS_QUERY } from './admin-console-activity-tags.gql';
import { GetAdminConsoleTags } from './__generated__/GetAdminConsoleTags';
import { GetActivitisActivityTags } from './__generated__/GetActivitisActivityTags';
import { AdminConsoleTag as ActivityTag } from './__generated__/AdminConsoleTag';
import { FormTagDialog } from './FormTagDialog';
import { ActivitiesList } from './ActivitiesList';
import { TagItem, ExtendTag } from './TagItem/TagItem';
import { AlertCard } from '../../../../../common/cards/alert-card/AlertCard';
import { useDeepEffect } from '../../../../../hooks/useDeepEffect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    containerPannel: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      marginTop: '2.25rem',
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: 20,
    },
    headingPanel: {
      fontWeight: 900,
      fontSize: '1.25rem',
      color: theme.color.grey.neutralBrand900,
    },
    bodyPannel: {
      fontWeight: 400,
      color: theme.color.grey.neutral200,
      fontSize: '0.875rem',
      marginTop: '6px',
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

type ActivityTagType = { [key: string]: { [key: string]: boolean } };

export function AdminConsoleTags() {
  const classes = useStyles();
  const [snackbar, setSnackBar] = React.useState<SnackBarOptions>();
  const { data: tagsData, refetch, loading: getTagsLoading } = useCampfireQuery<GetAdminConsoleTags, {}>(
    ADMIN_CONSOLE_TAG_QUERY
  );
  const [showTagDialog, setShowTagDialog] = React.useState(false);
  const [showActivitiesList, setShowActivitiesList] = React.useState(false);
  const [selectedTag, setSelectedTag] = React.useState<ActivityTag>();
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  const { data: activitiesData, refetch: refetchActivitiesTag } = useCampfireQuery<GetActivitisActivityTags, {}>(
    ACTIVITY_TAGS_QUERY
  );
  const [activitiesTagMap, setActivitiesTagMap] = React.useState<ActivityTagType>({});

  useDeepEffect(() => {
    if (activitiesData) {
      setActivitiesTagMap(
        activitiesData?.vm.activities.reduce(
          (acc, activity) => ({
            ...acc,
            [activity.activityId]: activity.activityTags.reduce(
              (accTag, currentTag) => ({
                ...accTag,
                [currentTag.activityTagId]: activity.activityTagged.some(
                  (activityTaggedItem) => activityTaggedItem.activityTag.activityTagId === currentTag.activityTagId
                ),
              }),
              {}
            ),
          }),
          {}
        )
      );
    }
  }, [activitiesData]);

  const onEdit = (tag: ActivityTag) => {
    setSelectedTag(tag);
    setShowTagDialog(true);
  };

  const onDelete = (tag: ActivityTag) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/tag/remove`,
        method: 'post',
        data: {
          activityTagId: tag.activityTagId,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Tag successfully removed',
        });
      });
  };

  const handleSuccess = (message: string) => {
    if (refetch) {
      setTimeout(refetch, 0);
    }
    handleClostDialogTag();
    setSnackBar({
      variant: 'success',
      open: true,
      message,
    });
  };

  const handleClostDialogTag = () => {
    setShowTagDialog(false);
    setSelectedTag(undefined);
  };

  const handleCloseActivitiesList = () => {
    setShowActivitiesList(false);
    setSelectedTag(undefined);
  };

  const handleChangeActivitiesList = (activityId: string) => {
    if (!selectedTag?.activityTagId) {
      return;
    }

    const activityTagId = selectedTag?.activityTagId;

    const updatingActivityTags = {
      ...activitiesTagMap,
      [activityId]: {
        ...activitiesTagMap[activityId],
        [activityTagId]: !activitiesTagMap[activityId][activityTagId],
      },
    };
    setActivitiesTagMap(updatingActivityTags);

    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/update-activity-tag`,
        method: 'post',
        data: {
          activityId,
          activityTagIds: Object.keys(updatingActivityTags[activityId]).filter(
            (key) => updatingActivityTags[activityId][key]
          ),
        },
      })
      .then(() => {
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Activity tag saved!',
        });
        if (refetchActivitiesTag) {
          setTimeout(refetchActivitiesTag, 0);
        }
      });
  };
  const handleShowActivitiesList = (tag: ActivityTag) => {
    setSelectedTag(tag);
    setShowActivitiesList(true);
  };

  const activityTags = tagsData?.vm.activityTags.map((activityTag) => ({
    ...activityTag,
    activityCount: (activitiesData?.vm.activities || []).filter((activity) =>
      activity.activityTags.some(
        (activitisActivityTag) =>
          activitisActivityTag.activityTagId === activityTag.activityTagId &&
          activity.activityTagged.some(
            (activityTaggedItem) => activityTaggedItem.activityTag.activityTagId === activitisActivityTag.activityTagId
          )
      )
    ).length,
  }));

  const selectableActivities = activitiesData?.vm.activities.map((activity) => ({
    ...activity,
    checked: Boolean(
      selectedTag?.activityTagId &&
        activitiesTagMap[activity.activityId] &&
        activitiesTagMap[activity.activityId][selectedTag?.activityTagId]
    ),
  }));

  const programOptions = activitiesData?.vm.activities
    ? uniqBy(
        activitiesData?.vm.activities.map((activity) => ({
          value: activity.program.programId,
          label: activity.program.name,
        })),
        'value'
      )
    : [];

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography variant='h2' className={classes.headingTitle}>
          Activity Tags
        </Typography>
        <TabletButton endIcon={<Add />} variant='contained' color='primary' onClick={() => setShowTagDialog(true)}>
          Add New Tag
        </TabletButton>
      </Box>
      <Box marginTop='1.5rem'>
        <AlertCard variant='info' title='What are Activity Tags?'>
          <Typography className={classes.infoBody}>
            Activity tags are a great way to categorise your activities by a whole range of dimensions outside of the
            existing program and activity structure.
            <br />
            This could mean types of activities (cooking, cleanup, etc.), tracking assets across activities, statuses,
            etc.
          </Typography>
        </AlertCard>
      </Box>
      {activityTags?.length ? (
        <Box className={classes.containerPannel}>
          <Typography className={classes.headingPanel} variant='h4'>
            Activity Tags
          </Typography>
          <Typography className={classes.bodyPannel}>
            You have {activityTags.length} tags for your activities. If you need to create more tags click the ‘
            <strong>Add New Tag</strong>’ button top right.
          </Typography>
          {activityTags?.map((tag: ExtendTag) => (
            <TagItem
              tag={tag}
              onEdit={onEdit}
              onDelete={onDelete}
              onShowActivities={handleShowActivitiesList}
              isLoading={getTagsLoading}
            />
          ))}
        </Box>
      ) : (
        <Box className={classes.containerPannel}>
          <Typography className={classes.headingPanel} variant='h4'>
            Create your first tag
          </Typography>
          <Typography className={classes.bodyPannel}>
            You haven’t created any activity tags yet. Click the add tag button top right to add your first.
          </Typography>
        </Box>
      )}
      {showActivitiesList && (
        <ActivitiesList
          activities={selectableActivities || []}
          onClose={handleCloseActivitiesList}
          title={selectedTag?.name || ''}
          onChange={handleChangeActivitiesList}
          programOptions={programOptions}
        />
      )}
      {showTagDialog && <FormTagDialog tag={selectedTag} onClose={handleClostDialogTag} onSuccess={handleSuccess} />}
      {snackbar && <TemplateSnackbar {...snackbar} onClose={() => setSnackBar(undefined)} />}
    </Box>
  );
}
