import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Typography,
  Badge,
} from '@material-ui/core';
import { object as YupObject, string as YupString, array as YupArray } from 'yup';
import { KeyboardArrowDown, AddCircle, Error } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useMemo, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { encodeTime } from '@campfire/hot-date';
import { SearchField } from '../../../../../../../common/inputs/SearchField';
import { Avatar } from '../../../../../user-profile/components/Avatar';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { TooltipMenu } from '../../../../../../../common/tooltip-menu';
import { useSaveCheckInFetch } from './CICOActions';
import { useSnackbar } from '../../../../../../../global/config/useSnackbar';
import { CICODateSelect } from './CICODateSelect';
import { useUser } from '../../../../../../../global/auth/useUser';
import { AddOtherVolunteersDialog } from './AddOtherVolunteersDialog';
import { ActivityActionType } from '../../../../../activities-v2/useActivityActions';

export interface SessionRosteringType {
  session: {
    sessionId: string;
    name: string;
  };
}

export interface VolunteerType {
  volunteerId: string;
  profile: {
    profileId: string;
    userId: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    avatarUrl: string | null;
  };
  isCheckedIn: boolean;
  sessionRosterings: SessionRosteringType[];
  CICOs: {
    CICOId: string;
    checkIn: any;
    checkOut: any | null;
    sessionReport: {
      session: {
        sessionId: string;
      };
      activityReport: {
        activity: {
          activityId: string;
        };
        activityDate: any;
      };
    };
  }[];
}

export interface MyActivityCheckInMultipleDialogProps {
  open: boolean;
  type: 'multiple' | 'myself';
  onClose: () => void;
  handleSuccess?: () => void;
  activity: ActivityActionType;
  volunteers: VolunteerType[];
}

export interface FromDataType {
  checkInItems: CheckInItemType[];
}

export interface CheckInItemType {
  sessionId: string;
  volunteerId: string;
  checkIn: Date | '';
}

const ADD_VOLUNTEER = 1;
const DETAIL = 2;

const validationSchema = YupObject().shape({
  checkInItems: YupArray().of(
    YupObject().shape({
      volunteerId: YupString().required('required'),
      sessionId: YupString().required('required'),
    })
  ),
});

export const MyActivityCheckInDialog = (props: MyActivityCheckInMultipleDialogProps) => {
  const { open, type, onClose, volunteers, activity, handleSuccess } = props;
  const [step, setStep] = useState(ADD_VOLUNTEER);
  const { theme } = useCampfireTheme();
  const { setSnackbar } = useSnackbar();
  const saveCheckIn = useSaveCheckInFetch();
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const [selectedVolunteers, setSelectedVolunteers] = useState<Array<VolunteerType>>([]);
  const [otherVolunteers, setOtherVolunteers] = useState<Array<VolunteerType>>([]);
  const [searchFilter, setSearchFilter] = useState<string>();
  const [openAddOther, setOpenAddOther] = useState(false);

  useEffect(() => {
    if (type === 'myself') {
      const volunteer = volunteers.find((item) => item.volunteerId === volunteerId);
      setStep(DETAIL);
      if (volunteer) {
        setSelectedVolunteers([volunteer]);
      }
    }
  }, [type, volunteers, volunteerId]);

  const initFormData = useMemo(() => {
    const data = [] as CheckInItemType[];
    selectedVolunteers.forEach((v) => {
      const sessionRosteringsList = v.sessionRosterings.filter(
        (sr) =>
          !v.CICOs.some(
            (CICO) =>
              CICO.checkIn &&
              CICO.checkOut &&
              CICO.sessionReport.activityReport.activityDate === activity.activityDate &&
              CICO.sessionReport.activityReport.activity.activityId === activity.activityId &&
              CICO.sessionReport.session.sessionId === sr.session.sessionId
          )
      );

      data.push({
        volunteerId: v.volunteerId,
        sessionId: sessionRosteringsList[0]?.session.sessionId || '',
        checkIn: '',
      });
    });
    return { checkInItems: data };
  }, [selectedVolunteers]);

  function handleClose() {
    onClose();
    setSearchFilter(undefined);
  }
  const filteredVolunteers = useMemo(
    () =>
      [...volunteers, ...otherVolunteers].sort((a, b) =>
        a.profile.preferredName.localeCompare(b.profile.preferredName)
      ),
    [volunteers, otherVolunteers]
  );

  function handleToggle(volunteer: VolunteerType) {
    if (volunteer.isCheckedIn) return;
    const selectedVolunteer = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
    if (!selectedVolunteer) {
      setSelectedVolunteers([...selectedVolunteers, volunteer]);
      return;
    }
    setSelectedVolunteers(selectedVolunteers.filter((x) => x.volunteerId !== volunteer.volunteerId));
  }

  const handleAddOther = (vols: VolunteerType[]) => {
    setOtherVolunteers([...otherVolunteers, ...vols]);
    setSelectedVolunteers([...selectedVolunteers, ...vols]);
  };

  return (
    <Formik
      initialValues={initFormData}
      enableReinitialize
      initialStatus={{
        startTimeInvalidRange: false,
        endTimeInvalidRange: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(vals) => {
        const checkInItemsParsed = vals.checkInItems.map((item) => ({
          ...item,
          checkIn: item.checkIn ? encodeTime(DateTime.fromJSDate(item.checkIn)) : encodeTime(DateTime.local()),
        }));

        saveCheckIn
          .run({ checkInItems: checkInItemsParsed, activityDate: activity.activityDate })
          .then((res) => {
            if (!res.ok) {
              setSnackbar({
                open: true,
                message: 'Unable to check in',
                variant: 'error',
              });
              return;
            }
            handleClose();
            setSnackbar({
              open: true,
              message: 'Checked in',
              variant: 'success',
            });
            if (handleSuccess) handleSuccess();
          })
          .catch(() =>
            setSnackbar({
              open: true,
              message: 'Unable to check in',
              variant: 'error',
            })
          );
      }}
    >
      {({ submitForm, setFieldValue, values }) => {
        const handleChangeSession = (sId: string, order: number) => {
          const newCheckInItems = [...values.checkInItems];
          newCheckInItems[order].sessionId = sId;
          setFieldValue('checkInItems', newCheckInItems);
        };

        return (
          <Form>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
              <DialogTitle>
                <Grid container justify='space-between'>
                  <Grid item>
                    {type === 'multiple' ? (
                      <Typography
                        style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
                      >{`Check In: ${step === ADD_VOLUNTEER ? 'Add Volunteers' : 'Details'}`}</Typography>
                    ) : (
                      <Typography
                        style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
                      >{`Check In`}</Typography>
                    )}
                  </Grid>
                  {type === 'multiple' && (
                    <Grid item>
                      <SearchField
                        placeholder='Search Volunteers'
                        growLeft
                        onChange={(e) => setSearchFilter(e.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
                {type === 'multiple' ? (
                  <Box pr={6}>
                    {step === ADD_VOLUNTEER ? (
                      <Typography
                        style={{ fontSize: 14 }}
                        color='textSecondary'
                      >{`Select one or more volunteers to check in. Hit 'Cancel' to close this window.`}</Typography>
                    ) : (
                      <Typography
                        style={{ fontSize: 14 }}
                        color='textSecondary'
                      >{`Please check the volunteers and start times below. Volunteers will be checked-in for their respective sessions.`}</Typography>
                    )}
                  </Box>
                ) : (
                  <Box pr={6}>
                    <Typography
                      style={{ fontSize: 14 }}
                      color='textSecondary'
                    >{`Please check the session and start time are correct before checking in.`}</Typography>
                  </Box>
                )}
              </DialogTitle>
              <DialogContent dividers style={{ paddingLeft: 0, paddingRight: 0, minHeight: 120 }} id='dialog-content'>
                {step === ADD_VOLUNTEER && (
                  <Box>
                    <Typography
                      style={{
                        fontSize: '20px',
                        fontWeight: 500,
                        color: theme.color.grey.neutral500,
                        marginLeft: '24px',
                      }}
                    >{`Rostered Team`}</Typography>
                    <List style={{ width: '100%' }}>
                      {filteredVolunteers
                        .filter((volunteer) =>
                          searchFilter
                            ? volunteer.profile.preferredName
                                .concat(` ${volunteer.profile.lastName}`)
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase())
                            : true
                        )
                        .map((volunteer) => {
                          const isSelected = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
                          return (
                            <VolunteerListItem
                              key={volunteer.volunteerId}
                              isSelected={!!isSelected}
                              volunteer={volunteer}
                              activity={activity}
                              handleToggle={handleToggle}
                            />
                          );
                        })}
                    </List>
                    <Box
                      display='flex'
                      alignItems='center'
                      ml={3}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setOpenAddOther(true)}
                    >
                      <AddCircle
                        style={{
                          fontSize: '18px',
                          color: theme.color.secondary.main900,
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          color: theme.color.secondary.main900,
                          marginLeft: '8px',
                        }}
                      >{`Add other volunteers`}</Typography>
                    </Box>
                    {openAddOther && (
                      <AddOtherVolunteersDialog
                        open={openAddOther}
                        activity={activity}
                        handleClose={() => setOpenAddOther(false)}
                        handleAddOther={handleAddOther}
                        currentVolunteers={volunteers}
                      />
                    )}
                  </Box>
                )}
                {step === DETAIL && (
                  <Box>
                    <Box width='100%' display='flex' alignItems='center' mb={1}>
                      <Box
                        pl={3}
                        style={{ flex: 1, fontSize: '13px', fontWeight: 600, color: theme.color.grey.neutral200 }}
                      >
                        {type === 'multiple' ? 'Volunteers' : 'Session'}
                      </Box>
                      <Box pr={2} style={{ fontSize: '13px', fontWeight: 600, color: theme.color.grey.neutral200 }}>
                        Start Time
                      </Box>
                    </Box>
                    <List style={{ width: '100%' }}>
                      {values.checkInItems.map((item, index) => {
                        const volunteer = selectedVolunteers.find((sV) => sV.volunteerId === item.volunteerId);
                        if (!volunteer) {
                          return null;
                        }
                        return (
                          <Box display='flex' alignItems='center' key={item.volunteerId}>
                            {type === 'multiple' ? (
                              <VolunteerListItemDetail
                                volunteer={volunteer}
                                order={index}
                                handleChangeSession={handleChangeSession}
                                selectedSession={item.sessionId}
                                activity={activity}
                              />
                            ) : (
                              <VolunteerMySelfDetail
                                volunteer={volunteer}
                                order={index}
                                handleChangeSession={handleChangeSession}
                                selectedSession={item.sessionId}
                                activity={activity}
                              />
                            )}
                            <CICODateSelect
                              onChange={(value) => setFieldValue(`checkInItems[${index}].checkIn`, value)}
                              field={`checkInItems[${index}].checkIn`}
                              time={item.checkIn}
                            />
                          </Box>
                        );
                      })}
                    </List>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box padding={2}>
                  <TabletButton variant='text' color='error' onClick={handleClose} style={{ marginRight: 8 }}>
                    {'Cancel'}
                  </TabletButton>
                  {step === ADD_VOLUNTEER ? (
                    <TabletButton
                      data-track='actCnl-add-volunteers'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setStep(DETAIL);
                      }}
                      disabled={selectedVolunteers.length === 0}
                    >
                      {'Next'}
                    </TabletButton>
                  ) : (
                    <TabletButton
                      data-track='actCnl-add-volunteers'
                      variant='contained'
                      color='primary'
                      type='submit'
                      onClick={submitForm}
                      disabled={values.checkInItems.some((item) => !item.sessionId)}
                    >
                      {'Check In'}
                    </TabletButton>
                  )}
                </Box>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

const VolunteerListItem = React.memo(
  (props: {
    volunteer: VolunteerType;
    isSelected: boolean;
    activity: ActivityActionType;
    handleToggle: (volunteer: VolunteerType) => void;
  }) => {
    const { theme } = useCampfireTheme();
    const { isSelected, volunteer, activity, handleToggle } = props;
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    const { sessionRosterings, CICOs } = volunteer;
    const isCompleted =
      sessionRosterings.length > 0 &&
      sessionRosterings.every((item) =>
        CICOs.some(
          (CICO) =>
            CICO.checkIn &&
            CICO.checkOut &&
            CICO.sessionReport.activityReport.activityDate === activity.activityDate &&
            CICO.sessionReport.activityReport.activity.activityId === activity.activityId &&
            CICO.sessionReport.session.sessionId === item.session.sessionId
        )
      );

    const handleSelectItem = () => {
      if (!isCompleted) {
        handleToggle(volunteer);
      }
    };
    return (
      <ListItem
        button
        onClick={handleSelectItem}
        style={{
          paddingLeft: 24,
          paddingRight: 32,
        }}
        disabled={volunteer.isCheckedIn || isCompleted}
      >
        <Box
          component='li'
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <FormControlLabel
            label=''
            disabled={volunteer.isCheckedIn || isCompleted}
            control={<Checkbox checked={isSelected} color='primary' />}
          />
          <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
          <Box>
            <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
            {isCompleted && (
              <Typography style={{ marginLeft: 8, fontSize: 12, color: theme.color.error[900] }}>
                This volutneer has already completed their sessions
              </Typography>
            )}
          </Box>
        </Box>
      </ListItem>
    );
  }
);

const VolunteerListItemDetail = React.memo(
  (props: {
    volunteer: VolunteerType;
    order: number;
    selectedSession: string;
    activity: ActivityActionType;
    handleChangeSession: (sId: string, order: number) => void;
  }) => {
    const { volunteer, order, selectedSession, activity, handleChangeSession } = props;
    const { theme } = useCampfireTheme();
    const [optionOpen, setOptionOpen] = useState(false);
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    const { sessions: allSessions } = activity;
    const { sessionRosterings, CICOs } = volunteer;
    const sessionRosteringsList = sessionRosterings.filter(
      (item) =>
        !CICOs.some(
          (CICO) =>
            CICO.checkIn &&
            CICO.checkOut &&
            CICO.sessionReport.activityReport.activityDate === activity.activityDate &&
            CICO.sessionReport.activityReport.activity.activityId === activity.activityId &&
            CICO.sessionReport.session.sessionId === item.session.sessionId
        )
    );

    const sessionRosteringSelected = sessionRosterings.find((item) => item.session.sessionId === selectedSession);
    const otherVolunteerSessionSelected = allSessions.find((item) => item.sessionId === selectedSession);

    return (
      <ListItem
        style={{
          paddingLeft: 24,
          paddingRight: 32,
        }}
      >
        <Box
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          {sessionRosterings.length || otherVolunteerSessionSelected ? (
            <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
          ) : (
            <Badge
              overlap='circle'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <Error
                  style={{
                    color: '#F87171',
                    backgroundColor: '#ffffff',
                    borderRadius: '50%',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
            >
              <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
            </Badge>
          )}

          <Box>
            <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
            {!sessionRosterings.length ? (
              <TooltipMenu
                open={optionOpen}
                onClose={() => {
                  setOptionOpen(false);
                }}
                title={
                  <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                    {allSessions.map((item) => (
                      <Button
                        key={item.sessionId}
                        onClick={() => {
                          handleChangeSession(item.sessionId, order);
                          setOptionOpen(false);
                        }}
                        style={{
                          color: theme.alert.blue.extraLight,
                          fontSize: '14px',
                          textTransform: 'none',
                        }}
                      >
                        {item.name}
                      </Button>
                    ))}
                  </Box>
                }
              >
                <Box
                  display='flex'
                  alignItems='center'
                  onClick={() => setOptionOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  {otherVolunteerSessionSelected ? (
                    <Typography style={{ marginLeft: 8, fontSize: 12, color: theme.color.grey.neutral400 }}>
                      Session: {otherVolunteerSessionSelected.name}
                    </Typography>
                  ) : (
                    <Typography style={{ marginLeft: 8, fontSize: 12, color: theme.color.grey.neutral400 }}>
                      Session: <span style={{ color: theme.color.secondary.main900 }}>Select a session</span>
                    </Typography>
                  )}
                  <KeyboardArrowDown style={{ fontSize: '13px', color: theme.color.secondary.main900 }} />
                </Box>
              </TooltipMenu>
            ) : sessionRosteringsList.length && sessionRosteringsList.length === 1 ? (
              <Box display='flex' alignItems='center'>
                <Typography style={{ marginLeft: 8, fontSize: 12, color: '#545454' }}>
                  Session: {sessionRosteringsList[0]?.session.name}
                </Typography>
              </Box>
            ) : (
              <TooltipMenu
                open={optionOpen}
                onClose={() => {
                  setOptionOpen(false);
                }}
                title={
                  <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 7px' }}>
                    {sessionRosteringsList.map((item) => (
                      <Button
                        key={item.session.sessionId}
                        onClick={() => {
                          handleChangeSession(item.session.sessionId, order);
                          setOptionOpen(false);
                        }}
                        style={{
                          color: theme.alert.blue.extraLight,
                          fontSize: '14px',
                          textTransform: 'none',
                        }}
                      >
                        {item.session.name}
                      </Button>
                    ))}
                  </Box>
                }
              >
                <Box
                  display='flex'
                  alignItems='center'
                  onClick={() => setOptionOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography style={{ marginLeft: 8, fontSize: 12, color: theme.color.grey.neutral400 }}>
                    Session: {sessionRosteringSelected?.session.name}
                  </Typography>
                  <KeyboardArrowDown style={{ fontSize: '13px', color: theme.color.grey.neutral400 }} />
                </Box>
              </TooltipMenu>
            )}
          </Box>
        </Box>
      </ListItem>
    );
  }
);

const VolunteerMySelfDetail = React.memo(
  (props: {
    volunteer: VolunteerType;
    order: number;
    selectedSession: string;
    activity: ActivityActionType;
    handleChangeSession: (sId: string, order: number) => void;
  }) => {
    const { volunteer, order, selectedSession, activity, handleChangeSession } = props;
    const { theme } = useCampfireTheme();
    const [optionOpen, setOptionOpen] = useState(false);
    const { sessionRosterings, CICOs } = volunteer;
    const sessionRosteringsList = sessionRosterings.filter(
      (item) =>
        !CICOs.some(
          (CICO) =>
            CICO.checkIn &&
            CICO.checkOut &&
            CICO.sessionReport.activityReport.activityDate === activity.activityDate &&
            CICO.sessionReport.activityReport.activity.activityId === activity.activityId &&
            CICO.sessionReport.session.sessionId === item.session.sessionId
        )
    );
    const sessionRosteringSelected = sessionRosterings.find((item) => item.session.sessionId === selectedSession);

    return (
      <ListItem
        style={{
          paddingLeft: 24,
          paddingRight: 32,
        }}
      >
        <Box
          display='flex'
          alignContent='center'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <Box>
            {sessionRosteringsList.length && sessionRosteringsList.length === 1 ? (
              <Box display='flex' alignItems='center'>
                <Typography style={{ marginRight: 8, color: theme.color.grey.neutral500 }}>
                  {sessionRosteringsList[0]?.session.name}
                </Typography>
              </Box>
            ) : (
              <TooltipMenu
                open={optionOpen}
                onClose={() => {
                  setOptionOpen(false);
                }}
                title={
                  <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                    {sessionRosteringsList.map((item) => (
                      <Button
                        key={item.session.sessionId}
                        onClick={() => {
                          handleChangeSession(item.session.sessionId, order);
                          setOptionOpen(false);
                        }}
                        style={{
                          color: theme.alert.blue.extraLight,
                          fontSize: '14px',
                          textTransform: 'none',
                        }}
                      >
                        {item.session.name}
                      </Button>
                    ))}
                  </Box>
                }
              >
                <Box
                  display='flex'
                  alignItems='center'
                  onClick={() => setOptionOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography style={{ marginRight: 8, color: theme.color.grey.neutral500 }}>
                    {sessionRosteringSelected?.session.name}
                  </Typography>
                  <KeyboardArrowDown style={{ fontSize: '13px', color: theme.color.grey.neutral400 }} />
                </Box>
              </TooltipMenu>
            )}
          </Box>
        </Box>
      </ListItem>
    );
  }
);
