import { TabletButton } from '@campfire/tablet-button';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { object as YupObject, string as YupString, array as YupArray } from 'yup';
import { DateTime } from 'luxon';
import React, { useMemo, useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import { Formik, Form } from 'formik';
import { encodeTime } from '@campfire/hot-date';
import { SearchField } from '../../../../../../../common/inputs/SearchField';
import { Avatar } from '../../../../../user-profile/components/Avatar';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { useSaveCheckOutFetch } from './CICOActions';
import { useSnackbar } from '../../../../../../../global/config/useSnackbar';
import { CICODateSelect } from './CICODateSelect';
import { useUser } from '../../../../../../../global/auth/useUser';
import { GET_MY_ROSTER_CHECKOUT } from './cico-get-volunteers.gql';
import { useCampfireQuery } from '../../../../../../../global/network/useCampfireQuery';
import { ActivityActionType } from '../../../../../activities-v2/useActivityActions';
import {
  GetMyRosterCheckoutVolunteers,
  GetMyRosterCheckoutVolunteersVariables,
  GetMyRosterCheckoutVolunteers_vm_activityReport_sessionReports_CICOs_volunteer as VolunteerType,
} from './__generated__/GetMyRosterCheckoutVolunteers';

export interface MyActivityCheckOutMultipleDialogProps {
  open: boolean;
  type: 'multiple' | 'myself';
  onClose: () => void;
  handleSuccess: (checkOutFinishedSessionId: string) => void;
  activity: ActivityActionType;
}

export interface FromDataType {
  checkOutItems: CheckOutItemType[];
}

export interface CheckOutItemType {
  CICOId: string;
  checkOut: Date | '';
}

const ADD_VOLUNTEER = 1;
const DETAIL = 2;

const validationSchema = YupObject().shape({
  checkOutItems: YupArray().of(
    YupObject().shape({
      CICOId: YupString().required('required'),
    })
  ),
});

export const MyActivityCheckOutDialog = (props: MyActivityCheckOutMultipleDialogProps) => {
  const { open, type, onClose, activity, handleSuccess } = props;
  const [step, setStep] = useState(ADD_VOLUNTEER);
  const { theme } = useCampfireTheme();
  const { setSnackbar } = useSnackbar();
  const saveCheckOut = useSaveCheckOutFetch();
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const [selectedVolunteers, setSelectedVolunteers] = useState<Array<VolunteerType>>([]);
  const [searchFilter, setSearchFilter] = useState<string>();

  const { data: checkOutData, loading: checkOutDataLoading } = useCampfireQuery<
    GetMyRosterCheckoutVolunteers,
    GetMyRosterCheckoutVolunteersVariables
  >(GET_MY_ROSTER_CHECKOUT, {
    options: {
      variables: {
        activityId: activity.activityId,
        activityDate: activity.activityDate,
      },
    },
  });
  // const sessions = checkOutData?.vm.activityReport?.activity.sessions || [];
  const sessionReports = checkOutData?.vm.activityReport?.sessionReports || [];
  const volunteers =
    sessionReports
      .flatMap((item) => item.CICOs)
      .map((c) => c.volunteer)
      .filter((v) => v.checkedIn) || [];
  const uniqVolunteers = uniqBy(volunteers, 'volunteerId');

  const currentVolunteer = uniqVolunteers.find((item) => item.volunteerId === volunteerId);

  useEffect(() => {
    if (type === 'myself') {
      setStep(DETAIL);
      if (currentVolunteer) {
        setSelectedVolunteers([currentVolunteer]);
      }
    }
  }, [type, currentVolunteer]);

  const initFormData = useMemo(() => {
    const data = [] as CheckOutItemType[];
    selectedVolunteers.forEach((item) => {
      if (item.checkedIn) {
        data.push({
          CICOId: item.checkedIn.CICOId,
          checkOut: '',
        });
      }
    });
    return { checkOutItems: data };
  }, [selectedVolunteers]);

  function handleClose() {
    onClose();
    setSearchFilter(undefined);
  }
  const filteredVolunteers = useMemo(
    () => uniqVolunteers.sort((a, b) => a.profile.preferredName.localeCompare(b.profile.preferredName)),
    [uniqVolunteers]
  );

  function handleToggle(volunteer: VolunteerType) {
    const selectedVolunteer = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
    if (!selectedVolunteer) {
      setSelectedVolunteers([...selectedVolunteers, volunteer]);
      return;
    }
    setSelectedVolunteers(selectedVolunteers.filter((x) => x.volunteerId !== volunteer.volunteerId));
  }

  return (
    <Formik
      initialValues={initFormData}
      enableReinitialize
      initialStatus={{
        startTimeInvalidRange: false,
        endTimeInvalidRange: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(vals) => {
        const checkOutItemsParsed = vals.checkOutItems.map((item) => ({
          ...item,
          checkOut: item.checkOut ? encodeTime(DateTime.fromJSDate(item.checkOut)) : encodeTime(DateTime.local()),
        }));

        saveCheckOut
          .run({ checkOutItems: checkOutItemsParsed })
          .then((res) => {
            if (!res.ok) {
              setSnackbar({
                open: true,
                message: 'Unable to check out',
                variant: 'error',
              });
              return;
            }
            handleClose();
            setSnackbar({
              open: true,
              message: 'Checked out',
              variant: 'success',
            });

            if (handleSuccess && currentVolunteer?.checkedIn)
              handleSuccess(currentVolunteer.checkedIn?.sessionReport.session.sessionId);
          })
          .catch(() =>
            setSnackbar({
              open: true,
              message: 'Unable to check out',
              variant: 'error',
            })
          );
      }}
    >
      {({ submitForm, setFieldValue, values }) => {
        return (
          <Form>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
              <DialogTitle>
                <Grid container justify='space-between'>
                  <Grid item>
                    {type === 'multiple' ? (
                      <Typography
                        style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
                      >{`Check Out: ${step === ADD_VOLUNTEER ? 'Volunteers' : 'Details'}`}</Typography>
                    ) : (
                      <Typography
                        style={{ fontSize: '24px', fontWeight: 500, color: theme.color.grey.neutral500 }}
                      >{`Check Out`}</Typography>
                    )}
                  </Grid>
                  {type === 'multiple' && (
                    <Grid item>
                      <SearchField
                        placeholder='Search Volunteers'
                        growLeft
                        onChange={(e) => setSearchFilter(e.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
                {type === 'multiple' ? (
                  <Box pr={6}>
                    {step === ADD_VOLUNTEER ? (
                      <Typography
                        style={{ fontSize: 14 }}
                        color='textSecondary'
                      >{`Select one or more volunteers to check out. Hit 'Cancel' to close this window.`}</Typography>
                    ) : (
                      <Typography
                        style={{ fontSize: 14 }}
                        color='textSecondary'
                      >{`Please check the volunteers and end times below. Volunteers will be checked-out for their respective sessions.`}</Typography>
                    )}
                  </Box>
                ) : (
                  <Box pr={6}>
                    <Typography
                      style={{ fontSize: 14 }}
                      color='textSecondary'
                    >{`Please check the details below before checking out.`}</Typography>
                  </Box>
                )}
              </DialogTitle>
              <DialogContent dividers style={{ paddingLeft: 0, paddingRight: 0, minHeight: 120 }} id='dialog-content'>
                {checkOutDataLoading ? (
                  <Box p={4}>{'Loading...'}</Box>
                ) : step === ADD_VOLUNTEER ? (
                  <Box>
                    <Box width='100%' display='flex' alignItems='center' mb={1}>
                      <Box
                        pl={3}
                        style={{ flex: 1, fontSize: '13px', fontWeight: 600, color: theme.color.grey.neutral200 }}
                      >
                        {type === 'multiple' ? 'Volunteers' : 'Session'}
                      </Box>
                      <Box pr={4} style={{ fontSize: '13px', fontWeight: 600, color: theme.color.grey.neutral200 }}>
                        Start Time
                      </Box>
                    </Box>
                    <List style={{ width: '100%' }}>
                      {filteredVolunteers
                        .filter((volunteer) =>
                          searchFilter
                            ? volunteer.profile.preferredName
                                .concat(` ${volunteer.profile.lastName}`)
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase())
                            : true
                        )
                        .map((volunteer) => {
                          const isSelected = selectedVolunteers.find((x) => x.volunteerId === volunteer.volunteerId);
                          return (
                            <Box display='flex' alignItems='center' key={volunteer.volunteerId}>
                              <VolunteerListItem
                                key={volunteer.volunteerId}
                                isSelected={!!isSelected}
                                volunteer={volunteer}
                                handleToggle={handleToggle}
                              />
                            </Box>
                          );
                        })}
                    </List>
                  </Box>
                ) : (
                  step === DETAIL && (
                    <Box>
                      <Box width='100%' display='flex' alignItems='center' mb={1}>
                        <Box
                          pl={3}
                          style={{ flex: 1, fontSize: '13px', fontWeight: 600, color: theme.color.grey.neutral200 }}
                        >
                          {type === 'multiple' ? 'Volunteers' : 'Session'}
                        </Box>
                        <Box pr={4} style={{ fontSize: '13px', fontWeight: 600, color: theme.color.grey.neutral200 }}>
                          End Time
                        </Box>
                      </Box>
                      <List style={{ width: '100%' }}>
                        {values.checkOutItems.map((item, index) => {
                          const volunteer = selectedVolunteers.find((sV) => sV.checkedIn?.CICOId === item.CICOId);
                          if (!volunteer) {
                            return null;
                          }
                          return (
                            <Box display='flex' alignItems='center' key={item.CICOId}>
                              {type === 'multiple' ? (
                                <VolunteerListItemDetail volunteer={volunteer} />
                              ) : (
                                <VolunteerMySelfDetail volunteer={volunteer} />
                              )}
                              <CICODateSelect
                                onChange={(value) => setFieldValue(`checkOutItems[${index}].checkOut`, value)}
                                field={`checkOutItems[${index}].checkOut`}
                                time={item.checkOut}
                              />
                            </Box>
                          );
                        })}
                      </List>
                    </Box>
                  )
                )}
              </DialogContent>
              <DialogActions>
                <Box padding={2}>
                  <TabletButton variant='text' color='error' onClick={handleClose} style={{ marginRight: 8 }}>
                    {'Cancel'}
                  </TabletButton>
                  {step === ADD_VOLUNTEER ? (
                    <TabletButton
                      data-track='actCnl-add-volunteers'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setStep(DETAIL);
                      }}
                    >
                      {'Next'}
                    </TabletButton>
                  ) : (
                    <TabletButton
                      data-track='actCnl-add-volunteers'
                      variant='contained'
                      color='primary'
                      type='submit'
                      onClick={submitForm}
                      disabled={checkOutDataLoading}
                    >
                      {'Check Out'}
                    </TabletButton>
                  )}
                </Box>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

const VolunteerListItem = React.memo(
  (props: { volunteer: VolunteerType; isSelected: boolean; handleToggle: (volunteer: VolunteerType) => void }) => {
    const { isSelected, volunteer, handleToggle } = props;
    const {
      profile: { preferredName, lastName, avatarUrl },
    } = volunteer;
    const volunteerName = `${preferredName} ${lastName}`;
    return (
      <ListItem
        button
        onClick={() => handleToggle(volunteer)}
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Box
          component='li'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            width: '100%',
          }}
        >
          <Box display='flex' alignContent='center' alignItems='center'>
            <FormControlLabel label='' control={<Checkbox checked={isSelected} color='primary' />} />
            <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
            <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
          </Box>
          <Typography
            style={{
              minWidth: 64,
              marginRight: '8px',
              fontSize: '13px',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            {volunteer.checkedIn &&
              DateTime.fromISO(volunteer.checkedIn.checkIn).toLocaleString({
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })}
          </Typography>
        </Box>
      </ListItem>
    );
  }
);

const VolunteerListItemDetail = React.memo((props: { volunteer: VolunteerType }) => {
  const { volunteer } = props;
  const {
    profile: { preferredName, lastName, avatarUrl },
  } = volunteer;
  const volunteerName = `${preferredName} ${lastName}`;
  const session = volunteer.checkedIn?.sessionReport.session;

  return (
    <ListItem
      style={{
        paddingLeft: 24,
        paddingRight: 32,
      }}
    >
      <Box
        display='flex'
        alignContent='center'
        alignItems='center'
        style={{
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Avatar preferredName={preferredName} lastName={lastName} avatarUrl={avatarUrl} size={32} />
        <Box>
          <Typography style={{ marginLeft: 8 }}>{volunteerName}</Typography>
          <Box display='flex' alignItems='center'>
            <Typography style={{ marginLeft: 8, fontSize: 12, color: '#545454' }}>Session: {session?.name}</Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
});

const VolunteerMySelfDetail = React.memo((props: { volunteer: VolunteerType }) => {
  const { volunteer } = props;
  const { theme } = useCampfireTheme();
  const session = volunteer.checkedIn?.sessionReport.session;

  return (
    <ListItem
      style={{
        paddingLeft: 24,
        paddingRight: 32,
        paddingBottom: 32,
      }}
    >
      <Box
        display='flex'
        alignContent='center'
        alignItems='center'
        style={{
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Box>
          <Box display='flex' alignItems='center'>
            <Typography style={{ marginRight: 8, color: theme.color.grey.neutral500 }}>{session?.name}</Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
});
